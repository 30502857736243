import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./rating.scss";

const PreReviewSubmit = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState("");
    const [step, setStep] = useState(0);

    const submitReviewRedirect = () => {
        if (selected === "graduate") {
            setStep(1);
            return setSelected("");
        } else if (selected === "undergrad") {
            navigate("/submit-review?type=module");
        } else if (selected === "ex-corp") {
            navigate("/submit-review?type=employability");
        } else if (selected === "course") {
            navigate("/submit-review?type=program");
        } else if (selected === "school") {
            navigate("/submit-review?type=institution");
        }
        
    }

  return (
    <div className="bg-gray-50">
        {
            step === 0 ?
            <div className="space-y-2 py-20  h-full md:w-3/4 p-5 pb-18 mx-auto text-centerss">
              <h2 className="h3 tracking-tighter">Which one describes you best?</h2>
              <p className="text-base text-gray-600">Please click on the option that best decribes your current status.</p>
  
              <div className=" top-16 w-full pb-5">
                  <h2
                  className={"pre-review-options text-xl my-3 cursor-pointer rounded-lg bg-white py-4 pl-4 pr-10 text-left shadow-md text-bg " + (selected === "undergrad" && "active") } 
                  onClick={() => setSelected("undergrad")}
                  >Undergraduate Student</h2>
  
                  <h2 
                  className={"pre-review-options text-xl my-3 cursor-pointer rounded-lg bg-white py-4 pl-4 pr-10 text-left shadow-md text-bg " + (selected === "graduate" && "active") } 
                  onClick={() => setSelected("graduate")}
                  >Recent Graduate (or Corp Member)</h2>
  
                  <h2 
                  className={"pre-review-options text-xl my-3 cursor-pointer rounded-lg bg-white py-4 pl-4 pr-10 text-left shadow-md text-bg " + (selected === "ex-corp" && "active") } 
                  onClick={() => setSelected("ex-corp")}>
                      Ex Corp Member (&lt; 2 years)</h2>
              </div>
              
              {
                  selected &&
              <button 
              className="rounded-md bg-sky-600 py-2 px-5 text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500" onClick={submitReviewRedirect}
              >Next &gt;&gt;</button>
              }
            </div>
            :
            <div className="space-y-2 py-20  h-full md:w-3/4 p-5 pb-18 mx-auto text-centerss">
              <h2 className="h3 tracking-tighter">Recent Graduate Options</h2>
              <p className="text-base text-gray-600">Please click on feedback you want to provide.</p>
  
              <div className=" top-16 w-full pb-5">
                  <h2
                  className={"pre-review-options text-xl my-3 cursor-pointer rounded-lg bg-white py-4 pl-4 pr-10 text-left shadow-md text-bg " + (selected === "course" && "active") } 
                  onClick={() => setSelected("course")}
                  >Feedback on your course of study.</h2>
  
                  <h2 
                  className={"pre-review-options text-xl my-3 cursor-pointer rounded-lg bg-white py-4 pl-4 pr-10 text-left shadow-md text-bg " + (selected === "school" && "active") } 
                  onClick={() => setSelected("school")}
                  >Feedback on your school.</h2>
              </div>
              
                  <div className="flex justify-between">
                    <button 
                    className="rounded-md bg-sky-600 py-2 px-5 text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500" onClick={() => {
                        setStep(0);
                        setSelected("");
                    }}
                    >&lt;&lt; Previous </button>

              {
                  selected &&
                    <button 
                    className="rounded-md bg-sky-600 py-2 px-5 text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500" onClick={submitReviewRedirect}
                    >Next &gt;&gt;</button>

              }
                  </div>
            </div>
        }

    </div>
  )
}

export default PreReviewSubmit
