import { useState } from "react"
// import Loader from "../../../../components/Loader"

const initialState = {
  password: "",
  oldPassword: "",
}

const ChangePassword = () => {
  const [form, setForm] = useState(initialState)


  const handleChange = (e) => {
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  return (
    <form
      className="relative bg-white px-5 py-10 flex flex-wrap gap-y-5 text-center rounded-lg"
      onSubmit={handleSubmit}
    >
        {/* <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50">
          <Loader type="large" dark />
        </div> */}
      <div className="flex flex-row flex-wrap gap-3">
        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="email">
            Previous Password
          </label>
          <input
            className="p-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="password"
            id="oldPassword"
            name="oldPassword"
            onChange={handleChange}
            value={form.oldPassword}
            placeholder="Previous Password"
            required
          />
        </div>

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="username">
            New Password
          </label>
          <input
            className="p-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="password"
            name="password"
            id="password"
            onChange={handleChange}
            value={form.password}
            placeholder="New Password"
            required
          />
        </div>
      </div>

      <button
        // disabled={isLoading ? true : false}
        type="submit"
        className="flex p-3 items-center justify-center w-full font-semibold bg-sky-600 text-sky-50 rounded-lg disabled:bg-opacity-70 flex-grow"
      >
        Change Password
      </button>
    </form>
  )
}

export default ChangePassword
