import RadioFeedback from "../../../../components/FeedbackElements/RadioFeedback"
import InputText from "../../../../components/FeedbackElements/InputText"
import SingleChoice from "../../../../components/FeedbackElements/SingleChoice"
import TextArea from "../../../../components/FeedbackElements/TextArea"

const FeedbackDetails = () => {
  return (
    <div className="feedbackdetails">
      <div className="feedbackblock">
        <FeedbackHead text="Course Details" required />

        <div className="detail">
          <InputText label="Course code" placeholder="Ex: BIO 101" config={{heading: ""}} />
          <InputText
            label="Course title"
            placeholder="Ex: Introduction to Biology"
            config={{heading: ""}}
          />
        </div>
      </div>

      <div className="feedbackblock">
        <FeedbackHead text="Session Details" required />

        <div className="detail">
          <InputText label="Academic session" placeholder="Ex: 2023" config={""} />
          <SingleChoice label="Academic semester" config={{options: []}} />
        </div>
      </div>

      <div className="feedbackblock">
        <FeedbackHead text="Feedback" required />
        <div className="space-y-6">
          <div className="space-y-2 border-l-4 border-gray-400 pl-4">
            <span className="text-lg font-semibold text-gray-700 tracking-tight">
              How easy is the course?
            </span>

            {/* <RadioFeedback data={[""]} /> */}
          </div>

          <div className="space-y-2 border-l-4 border-gray-400 pl-4">
            <span className="text-lg font-semibold text-gray-700 tracking-tight">
              Is the lecturer punctual?
            </span>

            {/* <RadioFeedback data={[""]} /> */}
          </div>
        </div>
      </div>

      <div className="feedbackblock">
        <FeedbackHead text="Course experience" />
        <div className="flex flex-wrap gap-6">
          <TextArea placeholder="Did you experience something you like or dislike about the course. Tell us about it..." config={""} />
        </div>
      </div>

      <button
        className="bg-sky-600 text-white w-full p-4 font-semibold text-lg rounded-lg"
        type="button"
      >
        Submit
      </button>
    </div>
  )
}

const FeedbackHead = ({ text, required = false }) => (
  <div>
    <span className="text-lg tracking-tight font-semibold text-black">
      {text}
    </span>
    {required && <span className="text-rose-500 text-lg">*</span>}
  </div>
)

export default FeedbackDetails
