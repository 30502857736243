import React from "react"

const Logo = ({ className: classes }) => {
  return (
    <svg
      style={{
        fillRule: "evenodd",
      }}
      className={`${classes} w-auto`}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="2.02862in"
      height="0.307346in"
      version="1.1"
      viewBox="0 0 5239.36 793.79"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_880171200">
          <g>
            <path
              className="fil0"
              d="M4365.75 0l0 0c94.91,0 172.56,77.64 172.56,172.56l0 621.23 -51.78 0 0 -623.18c0,-66.44 -54.35,-120.8 -120.79,-120.8l0 0c-66.45,0 -120.8,54.36 -120.8,120.8l0 623.18 -51.77 0 0 -621.23c0,-94.92 77.65,-172.56 172.56,-172.56z"
            />
            <rect
              className="fil1"
              x="4352.8"
              y="327.87"
              width="25.88"
              height="452.98"
            />
            <rect
              className="fil1"
              x="4288.09"
              y="556.51"
              width="23.22"
              height="225.82"
            />
            <rect
              className="fil1"
              x="4418.85"
              y="556.51"
              width="23.22"
              height="225.82"
            />
            <g>
              <path
                className="fil1"
                d="M4716.28 0l0 0c94.9,0 172.55,77.64 172.55,172.56l0 621.23 -51.77 0 0 -623.18c0,-66.44 -54.36,-120.8 -120.8,-120.8l0 0c-66.44,0 -120.79,54.36 -120.79,120.8l0 623.18 -51.78 0 0 -621.23c0,-94.92 77.65,-172.56 172.57,-172.56z"
              />
              <rect
                className="fil1"
                x="4703.33"
                y="327.87"
                width="25.88"
                height="452.98"
              />
              <rect
                className="fil1"
                x="4638.61"
                y="556.51"
                width="23.22"
                height="225.82"
              />
              <rect
                className="fil1"
                x="4769.37"
                y="556.51"
                width="23.22"
                height="225.82"
              />
            </g>
            <g>
              <path
                className="fil1"
                d="M5066.8 0l0 0c94.91,0 172.56,77.64 172.56,172.56l0 621.23 -51.78 0 0 -623.18c0,-66.44 -54.36,-120.8 -120.8,-120.8l0 0c-66.44,0 -120.79,54.36 -120.79,120.8l0 623.18 -51.77 0 0 -621.23c0,-94.92 77.65,-172.56 172.56,-172.56z"
              />
              <rect
                className="fil1"
                x="5053.85"
                y="327.87"
                width="25.88"
                height="452.98"
              />
              <rect
                className="fil1"
                x="4989.13"
                y="556.51"
                width="23.22"
                height="225.82"
              />
              <rect
                className="fil1"
                x="5119.89"
                y="556.51"
                width="23.22"
                height="225.82"
              />
            </g>
          </g>
          <path
            className="fil2"
            d="M259.48 717.77l-152.5 0 -29.09 70.38 -77.89 0 146.4 -328.46 75.07 0 146.87 328.46 -79.77 0 -29.09 -70.38zm-23.94 -57.71l-52.08 -125.76 -52.08 125.76 104.16 0z"
          />
          <path
            id="1"
            className="fil2"
            d="M555.09 793.79c-33.46,0 -63.73,-7.28 -90.79,-21.82 -27.06,-14.55 -48.33,-34.72 -63.82,-60.53 -15.49,-25.81 -23.22,-54.98 -23.22,-87.51 0,-32.54 7.74,-61.71 23.22,-87.52 15.49,-25.81 36.76,-45.98 63.82,-60.53 27.06,-14.54 57.48,-21.82 91.27,-21.82 28.46,0 54.2,5.01 77.19,15.02 22.99,10.02 42.31,24.4 57.95,43.17l-48.8 45.05c-22.22,-25.65 -49.74,-38.48 -82.59,-38.48 -20.34,0 -38.48,4.45 -54.43,13.37 -15.95,8.92 -28.39,21.35 -37.31,37.3 -8.92,15.95 -13.37,34.1 -13.37,54.44 0,20.33 4.45,38.48 13.37,54.43 8.92,15.95 21.35,28.38 37.31,37.3 15.95,8.92 34.09,13.37 54.43,13.37 32.84,0 60.37,-12.97 82.59,-38.94l48.8 45.05c-15.64,19.09 -35.04,33.64 -58.18,43.64 -23.15,10.02 -48.96,15.02 -77.43,15.02z"
          />
          <path
            id="2"
            className="fil2"
            d="M951.6 717.77l-152.5 0 -29.09 70.38 -77.89 0 146.4 -328.46 75.07 0 146.87 328.46 -79.77 0 -29.09 -70.38zm-23.94 -57.71l-52.08 -125.76 -52.08 125.76 104.16 0z"
          />
          <path
            id="3"
            className="fil2"
            d="M1094.72 459.69l149.22 0c35.66,0 67.18,6.8 94.55,20.41 27.36,13.62 48.63,32.77 63.82,57.49 15.17,24.71 22.76,53.48 22.76,86.34 0,32.84 -7.59,61.62 -22.76,86.34 -15.18,24.71 -36.45,43.87 -63.82,57.48 -27.37,13.61 -58.89,20.41 -94.55,20.41l-149.22 0 0 -328.46zm145.47 266.05c32.84,0 59.05,-9.14 78.59,-27.44 19.55,-18.3 29.34,-43.09 29.34,-74.37 0,-31.29 -9.78,-56.08 -29.34,-74.38 -19.54,-18.29 -45.75,-27.44 -78.59,-27.44l-69.45 0 0 203.64 69.45 0z"
          />
          <path
            id="4"
            className="fil2"
            d="M1693.93 717.77l-152.5 0 -29.09 70.38 -77.89 0 146.4 -328.46 75.07 0 146.87 328.46 -79.77 0 -29.09 -70.38zm-23.94 -57.71l-52.08 -125.76 -52.08 125.76 104.16 0z"
          />
          <polygon
            id="5"
            className="fil2"
            points="2135.95,788.16 2135.49,591.08 2038.82,753.43 2004.57,753.43 1908.38,595.31 1908.38,788.16 1837.05,788.16 1837.05,459.69 1899.93,459.69 2022.86,663.81 2143.93,459.69 2206.34,459.69 2207.27,788.16 "
          />
          <polygon
            id="6"
            className="fil2"
            points="2539.49,727.16 2539.49,788.16 2285.16,788.16 2285.16,459.69 2533.39,459.69 2533.39,520.69 2360.71,520.69 2360.71,592.02 2513.21,592.02 2513.21,651.14 2360.71,651.14 2360.71,727.16 "
          />
          <polygon
            id="7"
            className="fil2"
            points="2668.07,521.64 2562.96,521.64 2562.96,459.69 2849.18,459.69 2849.18,521.64 2744.07,521.64 2744.07,788.16 2668.07,788.16 "
          />
          <path
            id="8"
            className="fil2"
            d="M3099.29 788.16l-63.35 -91.5 -3.75 0 -66.16 0 0 91.5 -76.02 0 0 -328.46 142.18 0c29.09,0 54.35,4.85 75.77,14.55 21.43,9.69 37.94,23.46 49.51,41.29 11.57,17.82 17.36,38.94 17.36,63.34 0,24.4 -5.86,45.44 -17.59,63.11 -11.73,17.67 -28.39,31.21 -49.98,40.59l73.67 105.58 -81.64 0zm-1.41 -209.28c0,-18.46 -5.94,-32.61 -17.82,-42.46 -11.89,-9.85 -29.25,-14.77 -52.09,-14.77l-61.93 0 0 114.48 61.93 0c22.84,0 40.21,-5 52.09,-15.01 11.89,-10.02 17.82,-24.09 17.82,-42.24z"
          />
          <polygon
            id="9"
            className="fil2"
            points="3234.89,459.69 3310.91,459.69 3310.91,788.16 3234.89,788.16 "
          />
          <path
            id="10"
            className="fil2"
            d="M3545.99 793.79c-33.46,0 -63.73,-7.28 -90.79,-21.82 -27.06,-14.55 -48.33,-34.72 -63.82,-60.53 -15.49,-25.81 -23.22,-54.98 -23.22,-87.51 0,-32.54 7.74,-61.71 23.22,-87.52 15.49,-25.81 36.76,-45.98 63.82,-60.53 27.06,-14.54 57.48,-21.82 91.27,-21.82 28.46,0 54.2,5.01 77.19,15.02 22.99,10.02 42.31,24.4 57.95,43.17l-48.8 45.05c-22.22,-25.65 -49.74,-38.48 -82.59,-38.48 -20.34,0 -38.48,4.45 -54.43,13.37 -15.95,8.92 -28.39,21.35 -37.31,37.3 -8.92,15.95 -13.37,34.1 -13.37,54.44 0,20.33 4.45,38.48 13.37,54.43 8.92,15.95 21.35,28.38 37.31,37.3 15.95,8.92 34.09,13.37 54.43,13.37 32.84,0 60.37,-12.97 82.59,-38.94l48.8 45.05c-15.64,19.09 -35.04,33.64 -58.18,43.64 -23.15,10.02 -48.96,15.02 -77.43,15.02z"
          />
          <path
            id="11"
            className="fil2"
            d="M3837.86 793.79c-25.96,0 -51.06,-3.52 -75.32,-10.56 -24.24,-7.04 -43.71,-16.19 -58.42,-27.44l25.81 -57.25c14.08,10.32 30.82,18.61 50.21,24.87 19.39,6.25 38.79,9.39 58.18,9.39 21.59,0 37.54,-3.21 47.86,-9.62 10.32,-6.42 15.49,-14.94 15.49,-25.57 0,-7.82 -3.05,-14.32 -9.15,-19.47 -6.1,-5.17 -13.92,-9.31 -23.46,-12.44 -9.55,-3.13 -22.45,-6.57 -38.71,-10.33 -25.03,-5.94 -45.52,-11.89 -61.48,-17.82 -15.95,-5.95 -29.64,-15.49 -41.05,-28.62 -11.42,-13.14 -17.13,-30.66 -17.13,-52.56 0,-19.08 5.17,-36.36 15.49,-51.85 10.32,-15.49 25.89,-27.76 46.69,-36.83 20.8,-9.08 46.21,-13.62 76.25,-13.62 20.96,0 41.45,2.5 61.47,7.51 20.02,5 37.54,12.2 52.55,21.58l-23.46 57.72c-30.34,-17.21 -60.68,-25.82 -91.04,-25.82 -21.27,0 -36.99,3.45 -47.15,10.33 -10.17,6.88 -15.25,15.95 -15.25,27.21 0,11.27 5.87,19.63 17.59,25.11 11.73,5.47 29.64,10.87 53.73,16.19 25.02,5.95 45.51,11.89 61.47,17.84 15.95,5.94 29.64,15.32 41.06,28.16 11.42,12.82 17.13,30.18 17.13,52.08 0,18.77 -5.25,35.89 -15.72,51.38 -10.48,15.49 -26.2,27.76 -47.16,36.84 -20.96,9.07 -46.46,13.61 -76.49,13.61z"
          />
        </g>
      </g>
    </svg>
  )
}

export default Logo
