import { Tab } from "@headlessui/react"
import { useState } from "react"
import { getRandomId } from "../../../utils/RandomID"

import Editor from "./components/Editor"
import JSONSchema from "./components/JSONSchema"
import Preview from "./components/Preview"
import "./FeedbackEditor.scss"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const tabs = ["Editor", "Preview", "JSON Schema"]

const FeedbackEditor = () => {
  const [config, setConfig] = useState([
    {
      id: getRandomId(),
      type: "singlechoice",
      label: "",
      options: ["Option 1"],
    },
  ])

  const [desc, setDesc] = useState({
    title: "",
    description: "",
  })

  return (
    <Tab.Group>
      <Tab.List className="flex bg-sky-100 sticky top-16 z-10">
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            className={({ selected }) =>
              classNames(
                "py-2 px-4 text-sm font-medium",
                selected
                  ? "bg-sky-800 text-sky-50"
                  : "hover:bg-sky-100 text-sky-900 hover:text-sky-700"
              )
            }
          >
            {tab}
          </Tab>
        ))}
      </Tab.List>

      <Tab.Panels className="bg-gray-100">
        <Tab.Panel>
          <Editor
            desc={desc}
            setDesc={setDesc}
            config={config}
            setConfig={setConfig}
          />
        </Tab.Panel>
        <Tab.Panel>
          <Preview desc={desc} config={config} />
        </Tab.Panel>
        <Tab.Panel>
          <JSONSchema
            desc={desc}
            setDesc={setDesc}
            config={config}
            setConfig={setConfig}
          />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  )
}

export default FeedbackEditor
