import { DocumentAddIcon } from "@heroicons/react/solid"
import { Link } from "react-router-dom"
import FeedbackCard from "./components/FeedbackCard"
import "./Feedback.scss"
import { useEffect, useState } from "react"
import { User } from "../../../api"
import { toast } from "react-toastify"

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    User.getFeedbacks().then(res => {
      setFeedbacks(res.feedbacks);
    }).catch(err => {
      console.log(err);
      const error = err.response?.data || {message: "Network error"};
      toast.error(error.message);
    })
  }, [])


  return (
    <div className="feedbacks">
      <div className="head">
        <h1 className="h1">Feedbacks</h1>

        <Link to="/pre-review" className="submit-feedback">
          <DocumentAddIcon className="h-5" />
          Submit new feedback
        </Link>
      </div>

      <div>
        <span className="text-lg text-gray-500">All feedbacks</span>

        <div className="cards">

          {
            feedbacks.length > 0 ?
            feedbacks.map(feedback => {
              return (
                <FeedbackCard
                  type={
                    feedback.title.split(" ")[0].toLowerCase()
                  }
                  title={
                    feedback.title.split(" ")[0].toLowerCase() === "institution" ?
                    "School" : "School " + feedback.title.split(" ")[0].toLowerCase()
                  }
                  time={new Date(feedback.date).toDateString()}
                  key={feedback.submission_id}
                  status={"pending"}
                  id={feedback.submission_id}
                />
              )
            })

            :

            <p className="text-center">No feedback has been submitted yet.</p>
          }

          {/* <FeedbackCard
            type="faculty"
            title="Engineering"
            time="two weeks ago"
          />

          <FeedbackCard
            type="department"
            title="Mechanical Engineering"
            time="two weeks ago"
          />

          <FeedbackCard
            type="course"
            title="MEC 313: Workshop Practice"
            time="a week ago"
          /> */}
        </div>
      </div>
    </div>
  )
}

export default Feedback
