import React, { useEffect, useState, Fragment } from "react"
import SingleChoice from "../../../components/FeedbackElements/SingleChoice"
import InputText from "../../../components/FeedbackElements/InputText"
import TextArea from "../../../components/FeedbackElements/TextArea"
import MultiChoice from "../../../components/FeedbackElements/MultiChoice"
import RadioFeedback from "../../../components/FeedbackElements/RadioFeedback"
import DropListAutocomplete from "../../../components/DropListAutocomplete"
import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import { Feedback, Institution } from "../../../api"
import {toast} from "react-toastify"
import { useNavigate, Link } from "react-router-dom"
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import SchoolDropListAutocomplete from "../../../components/SchoolDropList"


const SubmitRating = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState([{id: "", value: "", q_id: ""}]);
  const [selected, setSelected] = useState();
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [steps, setSteps] = useState(0);
  const [typesteps, setTypeSteps] = useState(0);
  const [feedback, setFeedback] = useState({});
  const [feedbackQuestions, setFeedbackQuestions] = useState([]);
  const [questionsLoading, setQuestionsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [givenSchool, setGivenSchool] = useState([]);

  const givenType = new URLSearchParams(window.location.search).get("type")?.toLowerCase();
  const givenSchoolName = new URLSearchParams(window.location.search).get("sch");
  const unregisteredSubmissions = Cookies.get("unreg__sub") || 0;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (unregisteredSubmissions && parseInt(unregisteredSubmissions)>= 3 && !Cookies.get("_akad__usr__tk_")) {
      Swal.fire({
          icon: "info",
          title: "You need to create an account.",
          text: "You have exhausted the 3 submission limit for unregistered users. Please create an account to continue.",
          confirmButtonText: "Create account",
          cancelButtonText: "Leave",
          showCancelButton: true
        }).then(resp => {
          if (resp.isConfirmed) {
            return navigate("/signup");
          }
          navigate("/");
        })
    }

    Feedback.getFeedbackTypes().then(res => {
      setFeedbackTypes(res.feedbackTypes);

      if (givenType) {
        const givenTypeObj = res.feedbackTypes.find(feedback => {
          return feedback.title.toLowerCase().indexOf(givenType.toLocaleLowerCase()) === 0
        })

        if (givenTypeObj) {
          setSelected(givenTypeObj);
            setTypeSteps(1);
          // if (typesteps === 0) {
            startQuestion(givenTypeObj.id);
          // }
          
        } else {
          setSelected(res.feedbackTypes[0]);
        }

      } else {
        setSelected(res.feedbackTypes[0]);
      }
      
    }).catch(err => {
      const errorMsg = err.response?.data || {message: "An error occured, please check your network and refresh the page."} 
      toast.error(errorMsg.message);
      navigate("/");
    })

    return
  }, [navigate])


  useEffect(() => {
    
    Institution.getAll().then(res => {
      setInstitutions(res.schools);
      setGivenSchool(givenSchoolName);

      const uniQuestionId = feedbackQuestions.find(que => que.type === "institution")?.q_id;
      let parentIndex = form.findIndex((c) => c.q_id === uniQuestionId);
      let parent = form[parentIndex];
      const formClone = [...form];

      const schoolName = givenSchoolName || res.schools[0].name;
      let uniId = institutions.find(inst => inst.name === schoolName)?.id;
      formClone[parentIndex] = {...parent, value: uniId};
      
      if (uniId) {
        return Institution.getDepartments({school_id: uniId}).then(res => {
          setDepartments(res.departments);
      
          const deptQuestionId = feedbackQuestions.find(que => que.type === "department").q_id;
          let parentIndex = formClone.findIndex((c) => c.q_id == deptQuestionId);
          let parent = formClone[parentIndex];
          let deptId = res.departments[0].dept_id;
          formClone[parentIndex] = {...parent, value: deptId};

          setForm(formClone);
        })        
      }

      setForm(formClone);
    }).catch((err) => {
      toast.error("Network error. Go back a step and reload.", err.message);
      navigate("/");
    })
  }, [feedbackQuestions])

  const startQuestion = (feedbackID) => {
    setQuestionsLoading(true);
    Feedback.getFeedbackQuestions({feedback_id: feedbackID || selected.id, userId: Cookies.get("_akada__user_")}).then(res => {
      setFeedback(res.feedbackType);
      setFeedbackQuestions(res.feedbackQuestions);
      setQuestionsLoading(false);
      const formFormat = res.feedbackQuestions.map(que => ({ 
        id: que.id, 
        value: que.type === "dropdown" ? que.options[0] : (que.type === "institution" && institutions.length> 0) ? institutions[0].id : "", 
        q_id: que.q_id
      }))

      setForm(formFormat);
      setSteps(1);
      
    }).catch(err => {
      const errorMsg = err.response?.data || { message: "An Error occured, please check your network." };
      toast.error(errorMsg.message);
      setQuestionsLoading(false);
      setSteps(0);
    })
  }
  
  function handleChange(event, id) {
    let parentIndex = form.findIndex((c) => c.id === id)
    let parent = form[parentIndex]

    const formClone = [...form];
    formClone[parentIndex] = {...parent, value: event.target.value};

    setForm(formClone);
  }
  
  const handleSchoolChange = (value, id) => {
    let parentIndex = form.findIndex((c) => c.id === id);
    let parent = form[parentIndex];

    const formClone = [...form];
    let value_id = institutions.find(inst => inst.name === value).id;

    formClone[parentIndex] = {...parent, value: value_id};
    
    Institution.getDepartments({school_id: value_id}).then(res => {
      setDepartments(res.departments);
      
      const deptQuestionId = feedbackQuestions.find(que => que.type === "department").q_id;
      let parentIndex = formClone.findIndex((c) => c.q_id == deptQuestionId);
      let parent = formClone[parentIndex];

      // const formClone2 = [...formClone];
      let deptId = res.departments[0].dept_id;

      formClone[parentIndex] = {...parent, value: deptId};
      setForm(formClone);
    })
  }

  function handleDropChange(value, id, type) {
    let parentIndex = form.findIndex((c) => c.id === id)
    let parent = form[parentIndex]

    const formClone = [...form];
    let value_id = value;
    if (type === "dept") {
      value_id = departments.find(dept => dept.name === value).dept_id
    } else if (type === "school") {
      value_id = institutions.find(inst => inst.name === value).id
    }

    formClone[parentIndex] = {...parent, value: value_id};
    setForm(formClone);
  }

  function reactionChange(val, id) {
    let parentIndex = form.findIndex((c) => c.id === id)
    let parent = form[parentIndex]

    const formClone = [...form];
    formClone[parentIndex] = {...parent, value: val};

    setForm(formClone);
  }

  const submitForm = (e) => {
    e.preventDefault();
      console.log(form);
    const someIsEmpty = form.some(f => !f.value || f.value === "" || f.value.toString().trim() === "");
    if (someIsEmpty) {
      return toast.warn("Please fill all the inputs.")
    }
    setSubmitLoading(true);
    let formData = form.map(data => {
      return typeof data.value === "object" ? 
      {...data, value: data.value.name, question_id: data.q_id} 
      : 
      {...data, question_id: data.q_id};
    })

    formData = { 
      responses: [...formData], 
      user_type: Cookies.get("_akada__user_") ? "registered" : "anonymous", 
      feedback_id: selected.id, 
      userID: Cookies.get("_akada__user_")
    }
    
    Feedback.submit(formData).then(res => {
      setSubmitLoading(false);
      if (res.type && res.type === "error") {
        return toast.error(res.message);
      }

      if (Cookies.get("_akada__user_")) {
        toast.success("Your response has been recorded succesffuly");
        navigate("/users/feedbacks");
      } else {
        Swal.fire({
          icon: "success",
          title: "Response recorded",
          text: "Your response has been recorded succesfully. Help us do more with your response by creating a verified account",
          confirmButtonText: "Create account",
          cancelButtonText: "Leave",
          showCancelButton: true
        }).then(resp => {
          Cookies.set("unreg__sub", (parseInt(unregisteredSubmissions) + 1).toString());
          if (resp.isConfirmed) {
            Cookies.set("pend__sub", res.submission);
            return navigate("/signup");
          }
          navigate("/");
        })

      }
      
    }).catch(err => {
      setSubmitLoading(false);
      const error = err.response?.data || {message: "An error occured, please check your network and retry."};
      toast.error(error.message);
    })
  }


  return (
    <div className="bg-gray-50">

        {
          steps === 0 &&
          <div className="space-y-2 py-20 w-full h-full md:w-3/4 p-5 pb-18 mx-auto text-centerss">
            <h2 className="h3 tracking-tighter my-5">We're preparing your feedback questions...</h2>
            <div class="pre-review-loader"></div>
          </div>
          // <div className="space-y-2 py-20 w-full h-full md:w-3/4 p-5 pb-18 mx-auto text-centerss">
          //   <h2 className="h3 tracking-tighter">Select Review of Choice</h2>
          //   <p className="text-base text-gray-600">Choose the subject you would like to review from the dropdown below</p>

          //   <div className=" top-16 w-full pb-5">
          //     <Listbox value={selected?.title} onChange={setSelected}>
          //       <div className="relative mt-1 ">
          //         <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-4 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
          //           <span className="block truncate">{selected?.title}</span>
          //           <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          //             <ChevronDownIcon
          //               className="h-5 w-5 text-gray-400"
          //               aria-hidden="true"
          //             />
          //           </span>
          //         </Listbox.Button>
          //         <Transition
          //           as={Fragment}
          //           leave="transition ease-in duration-100"
          //           leaveFrom="opacity-100"
          //           leaveTo="opacity-0"
          //         >
          //           <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          //             {feedbackTypes.map((feedback, feedbackIdx) => (
          //               <Listbox.Option
          //                 key={feedbackIdx}
          //                 className={({ active }) =>
          //                   `relative cursor-default select-none py-2 pl-10 pr-4 ${
          //                     active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
          //                   }`
          //                 }
          //                 value={feedback}
          //               >
          //                 {({ selected }) => (
          //                   <>
          //                     <span
          //                       className={`block truncate ${
          //                         selected ? 'font-large' : 'font-large'
          //                       }`}
          //                     >
          //                       {feedback.title}
          //                     </span>
          //                     {selected ? (
          //                       <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
          //                         <CheckIcon className="h-5 w-5" aria-hidden="true" />
          //                       </span>
          //                     ) : null}
          //                   </>
          //                 )}
          //               </Listbox.Option>
          //             ))}
          //           </Listbox.Options>
          //         </Transition>
          //       </div>
          //     </Listbox>
          //   </div>
          //   <button 
          //   className="rounded-md bg-sky-600 py-2 px-5 text-lg font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
          //   onClick={() => startQuestion()}
          //   >{ questionsLoading ? "Loading questions..." : <>Next &gt;&gt;</>}</button>
          // </div>
        }


        {
        steps > 0 &&
        <form action="" onSubmit={submitForm}>
          <div className="w-full mt-4 md:w-3/4 p-5 pb-8 mx-auto bg-white border-t-6 border-black">
            <div className="space-y-2 pb-4 mb-4 border-b border-gray-200">
              {feedback.title && <h2 className="h2 tracking-tighter">{feedback.title}</h2>}
              {feedback.description && (
                <p className="text-base text-gray-600">{feedback.description}</p>
              )}
            </div>

            <p className="text-center font-bold mt-10">Step {steps} of {Math.ceil(feedbackQuestions.length/5)}</p>
            <div className="questions space-y-4">
              {feedbackQuestions.map((c, index) => (
                ((index < (steps * 5) ) && (index >= ((steps-1) * 5))) &&
                <div className="flex items-start gap-6" key={c.id}>

                <span className="h-6 w-6 bg-gray-700 text-white text-sm font-semibold flex items-center justify-center rounded-full">
                  {index + 1}
                </span>

                  {
                  c.type === "singlechoice" && 
                  <SingleChoice 
                  index={index}
                  config={c}
                  handleChange={(e) => handleChange(e, c.id)} 
                  value={form.find(f => f.id === c.id)?.value}
                  />
                  }

                  {
                  c.type === "shorttext" && 
                  <InputText index={index}
                  config={c}
                  handleChange={(e) => handleChange(e, c.id)} 
                  value={form.find(f => f.id === c.id).value}
                  />
                  }

                  { 
                  c.type === "longtext" && 
                  <TextArea 
                  index={index} 
                  config={c}
                  handleChange={(e) => handleChange(e, c.id)}
                  value={form.find(f => f.id === c.id).value}
                  /> 
                  }

                  {c.type === "multichoice" && (
                    <MultiChoice index={index} config={c} />
                  )}

                  {c.type === "dropdown" && (
                  <div className="flex flex-col gap-y-0">
                    {c.heading && <h5 className="h5 mb-0 mt-0 font-semibold">{c.heading}</h5>}
                    <label className="text-sm text-gray-600">{c.label}</label>
                    <DropListAutocomplete 
                    index={index} 
                    data={c.options} 
                    defaultSelected={ 
                      (form.find((fm) => fm.id === c.id)?.value ) || c.options[0]  || false
                    } 
                    onChange={(val) => handleDropChange(val, c.id, "random")}
                    />
                  </div>
                )}

                  {c.type === "institution" && (
                  <div className="flex flex-col gap-y-1 w-full">
                    {c.heading && <h5 className="h5 mb-0 mt-0 font-semibold">{c.heading}</h5>}
                    <label className="text-sm text-gray-600">{c.label}</label>
                    <SchoolDropListAutocomplete
                    index={index} 
                    data={institutions.map(inst => inst.name)}
                    defaultSelected={ 
                      // (institutions.find(inst => inst.id === form.find((fm) => fm.id === c.id).value )?.name)  || 
                      givenSchool || false
                    }
                    onChange={ (val)=> handleSchoolChange(val, c.id)}
                    type={"school"}
                    />
                  </div>
                )}
                
              {c.type === "department" && (
                <div className="flex flex-col gap-y-1 w-full">
                  {c.heading && <h5 className="h5 mb-0 mt-0 font-semibold">{c.heading}</h5>}
                  <label className="text-sm text-gray-600">{c.label}</label>
                  <DropListAutocomplete 
                  data={departments.map(dept => dept.name)} 
                  defaultSelected={ (departments.find(dept => dept.dept_id === form.find((fm) => fm.id === c.id).value )?.name) || departments.map(dept => dept.name)[0]}
                  onChange={(val) => handleDropChange(val, c.id, "dept")}
                  />
                </div>
              )}

                  {c.type === "reaction" && (
                  <div className="flex flex-col gap-y-0">
                    {c.heading && <h5 className="h5 mb-0 mt-0 font-semibold">{c.heading}</h5>}
                    <label className="text-sm text-gray-600">{c.label}</label>
                    <RadioFeedback 
                    index={index} 
                    config={c} 
                    data={c.options} 
                    handleChange={(val) => reactionChange(val, c.id)}
                    value={form.find(f => f.id === c.id).value}
                    onChange={handleDropChange}
                    />
                  </div>
                  )}
                </div>
              ))}
            </div>


            <div className="flex py-5 justify-between">

              {
                // steps >= Math.floor(feedbackQuestions.length/5) &&
                <button
                className="rounded-md bg-sky-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500" 
                type="button"
                onClick={() => {
                  if (steps === 1) {
                    return navigate("/pre-review")
                  } 
                  setSteps((step) => step-1)
                }
              }
                >&lt;&lt; Previous</button>
              }
              
              {
              steps < Math.ceil(feedbackQuestions.length/5) &&
              <button
              className="rounded-md bg-sky-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500" 
              type="button"
              onClick={() => setSteps((step) => step+1)}
              >Next &gt;&gt;</button>
              }
            </div>  

            {
              steps === Math.ceil(feedbackQuestions.length/5) &&
              <>
                <p className="text-center">
                  <input type="checkbox" required id="agreement" style={{width: "15px", height: "15px"}}/> 
                  <label htmlFor="agreement">&nbsp;By submitting this form, you agree to our <Link to={"/terms-and-conditions"} target="_blank" className="underline">TERMS AND CONDITIONS.</Link></label>
                </p>
                <button className="mt-6 w-full inline-flex justify-center rounded-md bg-sky-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500" type="submit">{submitLoading ? "Submitting response..." : "Submit"}</button>
              </>
            }
          </div>
        </form>
        }

    </div>
  )
}

export default SubmitRating
