import React, { useEffect, useState } from "react"

import schoolsData from "../../../assets/data/schools.json"
import coursesData from "../../../assets/data/courses.json"

import DropListAutocomplete from "../../../components/DropListAutocomplete"
import "./RequestData.scss"
import Loader from "../../../components/Loader"

/* const initialState = {
  email: "",
  phoneNumber: "",
  school: "",
  department: "",
  level: "",
} */

const schools = schoolsData.map((s) => s.name).sort()

const levels = [
  "100 Level",
  "200 Level",
  "300 Level",
  "400 Level",
  "500 Level",
  "600 Level",
  "700 Level",
]

const RequestData = () => {
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)

    setTimeout(() => {
      setLoading(false)

       // navigate("/users/dashboard")
    }, 2000)
  }

  return (
    <div>
      {/**<div className="requestdata-header">
        <Link
          className="nav-logo outline-none border-b border-gray-200 pb-6"
          to="/"
        >
          <Logo className=" logo-dark h-8 text-white logo" />
        </Link>

        <Link to="/" className="backtohome">
          <HomeIcon className="h-4" />
          <span>Back to home</span>
        </Link>
      </div>**/}

      <div className="requestdata">
        <div className="head">
          <h2 className="h2 tracking-tighter">Data Request</h2>
          <small>We just need some information to get you started.</small>
        </div>

        <form className="form" onSubmit={handleSubmit}>
          <div className="form-field-wrapper">
            <div className="form-field">
              <label htmlFor="email">Personal email</label>
              <input type="email" placeholder="Enter your email" required />
            </div>

            <div className="form-field">
              <label htmlFor="email">Phone number</label>
              <input
                type="tel"
                placeholder="Enter your phone number"
                required
              />
            </div>

            <div className="flex flex-col gap-y-1">
              <small className="text-sm tracking-tight font-semibold">
                Select school
              </small>
              <DropListAutocomplete data={schools} onChange={console.log} />
            </div>

            <div className="flex flex-col gap-y-1">
              <small className="text-sm tracking-tight font-semibold">
                Select course
              </small>
              <DropListAutocomplete data={coursesData} onChange={console.log} />
            </div>

            <div className="flex flex-col gap-y-1">
              <small className="text-sm tracking-tight font-semibold">
                Select level
              </small>
              <DropListAutocomplete data={levels} onChange={console.log} />
            </div>
          </div>

          <button className="btn" type="submit">
            {loading ? <Loader /> : "Continue"}
          </button>
        </form>
      </div>
    </div>
  )
}

// Personal Email, Phone Number, School, Department, Level

export default RequestData
