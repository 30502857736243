import { DatabaseIcon, TrendingUpIcon } from "@heroicons/react/solid"

const DashboardHeader = () => {
  return (
    <div className="dashboardheader">
      <div className="head">
        <h4 className="h4">Welcome, Imran Paul</h4>
        <small>Administrator</small>
      </div>

      <div className="stats">
        <div className="stat">
          <DatabaseIcon />

          <div>
            <h5 className="h5">104</h5>
            <small>Total data requests</small>
          </div>
        </div>

        <div className="stat">
          <TrendingUpIcon />

          <div>
            <h5 className="h5">17,434</h5>
            <small>Feedbacks submitted</small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardHeader
