import { AcademicCapIcon } from "@heroicons/react/outline"
import React, {useState, useEffect} from "react"
import {useNavigate} from "react-router-dom";
import { Institution } from "../../../../api";
import starRatingImg from "../../../../assets/image/blue-rating-2.jpg"

const InstitutionRating = () => {
    const [schools, setSchools] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
  
      Institution.getAll().then(res => {
        if (res.type !== "error") setSchools(res.schools);
      })
  
      return
    }, [navigate])

    
  const handleSchoolChange = (e) => {
    const school = schools.find(sch => sch.name === e.target.value);
    if (school) {
      navigate("/submit-review?type=institution&sch=" + school.name);
    }
  }

    return (
        <div 
        style={{
            background: `darkblue url(${starRatingImg}) no-repeat left 20%`,
            backgroundSize: "cover"
        }}
        className="flex justify-start px-12 py-20 items-center flex-wrap"
        >

            <div className="md:w-[40%]">
                <div className="text-lg text-white my-5">
                    <h4 className="h4">Enter your school to get started</h4> 
                </div>

                <div className="relative w-full h-14">
                    <AcademicCapIcon
                        className="absolute top-3 left-4 h-7 z-10 text-gray-700"
                        strokeWidth={1}
                    />
                    <input
                        className="absolute top-0 left-0 w-full h-full text-base pl-14 px-4 py-2 rounded-full"
                        type="text"
                        placeholder="Your school"
                        list="schoollist"
                        onChange={handleSchoolChange}
                    />
                    <datalist id="schoollist">
                        {
                        schools.length > 0 && 
                        schools.map((sch, i) => {
                            return <option value={sch.name} id={sch.id} key={i}></option>
                        })
                        }
                    </datalist>
                </div>
            </div>

        </div>
    )
}

export default InstitutionRating;
