import React from "react"
import { Link, useLocation } from "react-router-dom"

const BottomNavLink = ({ text, to }) => {
  const { pathname } = useLocation()

  return (
    <li className={pathname === to ? 'active': undefined}>
      <Link to={to}>{text}</Link>
    </li>
  )
}

export default BottomNavLink
