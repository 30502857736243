import { useState } from "react"
import { RadioGroup } from "@headlessui/react"
import { useEffect } from "react"

/* const choices = [
  {
    name: "Strongly Disagree",
    icon: "😖",
  },
  {
    name: "Disagree",
    icon: "😖",
  },
  {
    name: "Slightly Agree",
    icon: "😐",
  },
  {
    name: "Agree",
    icon: "😎",
  },
  {
    name: "Strongly Agree",
    icon: "😎",
  },
] */

export default function RadioFeedback({ data, handleChange, value, disabled = false }) {
  const [choices, setChoices] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const mappedData = data.map((d, index) => {
      let median = Math.floor(data.length / 2)

      if (index < median) return { name: d, icon: "😖" }
      if (index > median) return { name: d, icon: "😎" }
      else return { name: d, icon: "😐" }
    })

    setChoices(mappedData)
  }, [])

  useEffect(() => {
    setSelected(value)
  }, [])

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <RadioGroup.Label className="sr-only">Rate</RadioGroup.Label>
      <div className="flex flex-wrap gap-2">
        {choices.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            className={({ active, checked }) =>
              `${
                active
                  ? "ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300"
                  : "ring-2 ring-gray-200"
              }
                  ${
                    (value.name === plan.name ) ? "bg-sky-900 bg-opacity-75 text-white" : "bg-white"
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
            }
            disabled={disabled}
          >
            {({ active, checked }) => (
              <>
                <div className="flex w-full items-center gap-2">
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className={`font-medium text-xl  ${
                          checked ? "text-white" : "text-gray-900"
                        }`}
                      >
                        {plan.icon}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className={`inline text-sm ${
                          (value.name === plan.name ) ? "text-sky-100" : "text-gray-500"
                        }`}
                      >
                        <span>{plan.name}</span>
                      </RadioGroup.Description>
                    </div>
                  </div>
                  {(value.name === plan.name ) && (
                    <div className="shrink-0 text-white">
                      <CheckIcon className="h-6 w-6" />
                    </div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
