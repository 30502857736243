import http from "axios";
import Cookies from "js-cookie";

const serverurl = "https://serverapi.akadametrix.com";
// const serverurl = "http://localhost:5000";

const requestOptions = (datatype = null) => {
    let options = {};
    const token = Cookies.get("_akad__usr__tk_");
    if (token) {
        options = {
            ...options,
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": datatype,
                "Access-Control-Allow-Origin": "*",
                "Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            }
        }
    }
    return options;
}

const response = (res) => res.data;

const request = {
    get: (url) => http.get(`${serverurl}${url}`, requestOptions("application/json") ).then(response),
    post: (url, data) => http.post(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    put:(url, data)=> http.put(`${serverurl}${url}`, data, requestOptions("application/json")).then(response),
    delete:(url, data)=> http.delete(`${serverurl}${url}`, data, requestOptions("application/json")).then(response)
}

const Feedback = {
    getFeedbackTypes: (params)=> request.post("/feedbacks/getTypes", params),
    getFeedbackQuestions: (params)=> request.post("/feedbacks/getQuestions", params),
    submit: (params)=> request.post("/feedbacks/submit", params),
    updateReviewer: (params)=> request.post("/feedbacks/updateReviewer", params),
    getSubmissionAnswers: (params)=> request.post("/feedbacks/submissionAnswers", params),
}

const Institution = {
    getAll: (params)=> request.get("/institutions", params),
    getSchoolDetails: (params)=> request.post("/institutions/schoolDetails", params),
    getDepartments: (params)=> request.post("/institutions/getDepartments", params),
    getAllDepartments: (params)=> request.post("/institutions/getAllDepartments", params),
}

const Message = {
    sendContactMessage: (params)=> request.post("/messages/contactMessage", params)
}

const User = {
    login: (params)=> request.post("/users/login", params),
    signup: (params)=> request.post("/users/signup", params),
    verifyEmail: (params)=> request.post("/users/verifyEmail", params),
    resendEmailVerificationKey: (params)=> request.post("/users/resendEmailKey", params),
    getProfile: (params)=> request.post("/users/getProfile", params),
    updateAccount: (params)=> request.post("/users/updateAccount", params),
    getFeedbacks: (params)=> request.post("/users/getFeedbacks", params),
}

export {serverurl, Feedback, Institution, User, Message};