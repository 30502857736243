import { useState, useRef, useEffect } from "react"
import { toast } from "react-toastify"
import { PhotographIcon } from "@heroicons/react/outline"

import schoolsData from "../../../../assets/data/schools.json"
import coursesData from "../../../../assets/data/courses.json"

// import Loader from "../../../../components/Loader"
import dummyPhoto from "../../../../assets/image/default.png"
import CanvasImage from "../../../../utils/CanvasImage"
import DropListAutocomplete from "../../../../components/DropListAutocomplete"
import { User } from "../../../../api"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

const schools = schoolsData.map((s) => s.name).sort()

const levels = [
  "100 Level",
  "200 Level",
  "300 Level",
  "400 Level",
  "500 Level",
  "600 Level",
  "700 Level",
]

const UpdateProfile = () => {
  const [source, setSource] = useState(null);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    fname: "",
    lname: "",
    phone: "",
    studentEmail: "",
    personalEmail: "",
    profilePhoto: "",
    school: "",
    course: "",
    level: ""
  })

  const filePickerRef = useRef(null)
  function handleFileChange(event) {
    const FILE_TYPES = ["image/png", "image/jpg", "image/jpeg", "image/webp"]

    if (event.target.files.length < 1) {
      return
    }

    if (!FILE_TYPES.includes(event.target.files[0].type)) {
      toast.error("File type should be either a png, jpg, webp")
      return
    }

    if (event.target.files[0].size > 1024 * 1024 * 2) {
      toast.error("Image size should not be larger than 2MB")
      return
    }

    const reader = new FileReader()

    reader.addEventListener("load", (e) => {
      return setSource(reader.result)
    })

    reader.readAsDataURL(event.target.files[0])
  }

  useEffect(() => {
    User.getProfile({userID: Cookies.get("_akada__user_")}).then(res => {
      if (res.type === "error") {
        toast.error(res.message);
        return navigate("/");
      }
      console.log(res.profile);
      setForm({...res.profile, phone: res.profile.phone1});
    }).catch(err => {
      const error = err.response?.data || {message: "Network error"};
      return toast.error(error.message);
    })
  }, [])

  const handleChange = (e) => {
    setForm((form) => ({
      ...form,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
  }

  return (
    <form
      className="relative bg-white px-5 py-10 flex flex-col gap-y-5 text-center rounded-lg"
      onSubmit={handleSubmit}
    >
      {/* {isLoading && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-50">
          <Loader type="large" dark />
        </div>
      )}
 */}
      <div className="relative h-28 w-28 md:h-40 md:w-40">
        <img
          className="h-full w-full object-cover rounded-full"
          src={dummyPhoto}
          alt=""
        />
        <span
          className="absolute bottom-3 right-3 md:bottom-6 md:right-6 bg-black bg-opacity-70 hover:bg-opacity-50 cursor-pointer flex items-center justify-center p-2 rounded-full"
          onClick={() => filePickerRef.current.click()}
        >
          <PhotographIcon className="h-5 text-white" />
        </span>
      </div>

      <input
        type="file"
        ref={filePickerRef}
        onChange={handleFileChange}
        hidden
      />

      <CanvasImage
        source={source}
        setSelectedFile={(file) =>
          setForm((form) => ({
            ...form,
            profilePhoto: file,
          }))
        }
        type="profile"
      />

      <div className="flex flex-row flex-wrap gap-3">
        {
          !form.studentEmail &&
          <div className="flex flex-col gap-y-1 flex-grow items-start">
            <label className="text-gray-500 text-sm" htmlFor="studentemail">
              Student Email
            </label>
            <input
              className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
              type="text"
              id="studentemail"
              name="studentemail"
              onChange={handleChange}
              value={form.studentEmail}
              placeholder="Student Email"
              required
            />
          </div>
        }

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="fname">
            First Name
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="text"
            name="fname"
            id="fname"
            onChange={handleChange}
            value={form.fname}
            placeholder="First Name"
            required
          />
        </div>

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="lname">
            Last Name
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="text"
            name="lname"
            id="lname"
            onChange={handleChange}
            value={form.lname}
            placeholder="Last Name"
            required
          />
        </div>

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="personalEmail">
            Personal Email
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="email"
            name="personalEmail"
            id="personalEmail"
            onChange={handleChange}
            value={form.personalEmail}
            placeholder="Personal Email"
            required
          />
        </div>

        <div className="flex flex-col gap-y-1 flex-grow items-start">
          <label className="text-gray-500 text-sm" htmlFor="phoneNumber">
            Phone Number
          </label>
          <input
            className="py-2 px-3 md:min-w-[300px] bg-gray-50 rounded-lg border-[1px] border-gray-200 w-full"
            type="text"
            name="phone"
            id="phoneNumber"
            onChange={handleChange}
            value={form.phone}
            placeholder="Phone Number"
            required
          />
        </div>
      </div>

      <div className="flex flex-col items-start gap-y-1">
        <small className="text-sm text-gray-500">Select school</small>
        <DropListAutocomplete data={schools} onChange={console.log} />
      </div>

      <div className="flex flex-col items-start gap-y-1">
        <small className="text-sm text-gray-500">Select course</small>
        <DropListAutocomplete data={coursesData} onChange={console.log} />
      </div>

      <div className="flex flex-col items-start gap-y-1">
        <small className="text-sm text-gray-500">Select level</small>
        <DropListAutocomplete data={levels} onChange={console.log} />
      </div>

      <button
        // disabled={isLoading ? true : false}
        type="submit"
        className="flex py-2 px-3 items-center justify-center w-full bg-sky-600 text-white font-semibold rounded-lg disabled:bg-opacity-70 flex-grow"
      >
        Update Profile
      </button>
    </form>
  )
}

export default UpdateProfile
