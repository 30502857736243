import React from "react";
import "./terms.scss";

const TermsAndConditions = () => {

  return (
    <div>

      <div className="requestdata">
        <div className="head">
          <h2 className="h2 tracking-tighter">Terms and Conditions</h2>
        </div>
        <p>Welcome to the AkadaMetrix Student Experience Survey (ASES) in Nigeria. Please read these Terms and Conditions carefully before participating in the survey. By accessing and using our website and participating in the survey, you acknowledge that you have read, understood, accepted, and agreed to comply with these Terms and Conditions and any additional documents or policies referred to in or incorporated into these Terms, whether you are participating as a guest or as a registered user of this application:</p>

        <div className="paragraph">
          <h3 className="h4 tracking-tightern">Survey Participation:</h3>
          <p>A. <b>Eligibility:</b> The survey is open to current and recent (not more than three years post-graduation) students enrolled in Nigerian universities, polytechnics and colleges.</p>
          <p>B. <b>Accuracy:</b> Participants are responsible for providing accurate and truthful information in the survey.</p>
          <p>C. <b>Consent:</b> By participating in the survey, you consent to the use of your responses for research and analytical purposes.</p>
        </div>

        <div className="paragraph">
          <h3 className="h4">Confidentiality and Anonymity:</h3>
          <p>A. <b>Data Confidentiality:</b> All survey responses will be treated with strict confidentiality. Your personal information will not be disclosed or shared with any third parties without your consent.</p>
          <p>B. <b>Anonymity:</b> Your survey responses will be kept anonymous. Your personal information will not be linked to your survey responses in any way.</p>
        </div>

        <div className="paragraph">
          <h3 className="h4">Data Protection:</h3>
          <p>A. <b>Data Security:</b> We employ industry-standard security measures to protect the personal information you provide during the survey.</p>
          <p>B. <b>Data Usage:</b> Your survey responses may be analysed and used for research purposes. <b>Aggregated and anonymised</b> data may be shared with educational institutions, policymakers, and other stakeholders to drive positive changes in the education system.</p>
          <p>B. <b>Data Retention:</b> We will retain your survey responses for the necessary period to fulfill the objectives of the survey. </p>
        </div>

        <div className="paragraph">
          <h3 className="h4">Intellectual Property:</h3>
          <p>A. <b>Ownership:</b> All content and materials provided on our website, including text, graphics, logos, and images, are protected by intellectual property rights and are owned by us or our licensors.</p>
          <p>B. <b>Limited Use:</b> You may access and use the content on our website solely for personal, non-commercial purposes. You may not modify, distribute, or reproduce any content without our prior written consent.</p>
        </div>

        <div className="paragraph">
          <h3 className="h4">Modification of Terms and Conditions:</h3>
          <p>We reserve the right to modify or update these Terms and Conditions at any time. Any changes will be effective immediately upon posting on our website. If these Terms have materially changed since you last accessed or used the services, you acknowledge and agree that your continued access or use of the Services constitutes your acceptance of the changed Terms; and;</p>
          <p>You represent and warrant that you are at least sixteen years of age and have the right, authority, and capacity to enter into these Terms, either on behalf of yourself or the entity that you represent.</p>
        </div>

        <div className="paragraph">
          <h3 className="h4">Limitation of Liability:</h3>
          <p>A.  While we strive to provide accurate and reliable information, we do not guarantee the accuracy or completeness of the survey results, or any information provided on our website.</p>
          <p>B. You agree you shall not post on or transmit to this site or platform any material or information that is incorrect, defamatory, privileged, confidential or exempt from disclosure under applicable laws, libellous, infringing upon the intellectual property rights of others, or otherwise objectionable, unlawful, obscene, profane, pornographic, threatening, or that could constitute or encourage conduct(s) that would be considered criminal offence(s) and/or give rise to civil liability(ies), whether under statute, common law, or equitable principles, or otherwise could violate any other laws in Nigeria or elsewhere.</p>
          <p>C. You further agree to defend, indemnify in full, and hold our company, its subsidiaries, employees, directors, and/or officers harmless from and against any and all claims, damages, costs and expenses, including but not limited to attorneys' fees, arising from or related to such postings or transmissions of the information or contents shared with our company.</p>
          <p>D.  We do not monitor and bear no responsibility and/or liability for the contents, opinions, and information related by third parties on this site. The content of this site, including but not limited to factual statements and opinions, are the sole responsibility of their creators. They do not represent, implicitly or explicitly, the positions, policies, or our opinions of our Company or her directors; and </p>
          <p>E. We shall not therefore be liable for any indirect, incidental, consequential, liability, injury or punitive damages arising out of your participation in the survey or use of our website/application/platform.</p>
        </div>
        
        <div className="paragraph">
          <h3 className="h4">Governing Law and Jurisdiction:</h3>
          <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Nigeria. Any disputes arising out of or relating to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Nigeria.</p>
          <p>If you have any questions or concerns regarding these Terms and Conditions, please contact us using the provided contact information.</p>
          <p>Thank you for your cooperation and for being a part of the National Student Experience Survey in Nigeria.</p><br />
        </div>

      </div>
      
    </div>
  )
}

export default TermsAndConditions
