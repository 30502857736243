import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import UsersNavbar from '../components/UsersNavbar'
import Cookies from "js-cookie";

const UsersLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!Cookies.get("_akad__usr__tk_") || !Cookies.get("_akada__user_") || Cookies.get("_akada__user_").length < 1) {
      return navigate("/login");
    }
  }, [])

  return (
    <div>
        <UsersNavbar />

        <div className='w-full p-8 lg:px-16 lg:py-8'><Outlet /></div>

        <footer className='text-center px-10 py-5 mt-10 bg-gray-50 font-bold'>
          &copy; AkadaMetrix {new Date().getFullYear()}. All rights reserved.
        </footer>
    </div>
  )
}

export default UsersLayout