import { DuplicateIcon, TrashIcon } from "@heroicons/react/outline"
import { DocumentAddIcon, XIcon } from "@heroicons/react/solid"

import DropListAutocomplete from "../../../../components/DropListAutocomplete"
import { getRandomId } from "../../../../utils/RandomID"

const elements = {
  shorttext: {
    type: "shorttext",
    text: "Short Answer",
    config: ["heading", "placeholder", "label", "maxLength"],
  },
  longtext: {
    type: "longtext",
    text: "Paragraph",
    config: ["heading", "placeholder", "label", "maxLength"],
  },
  singlechoice: {
    type: "singlechoice",
    text: "Single Choice",
    config: ["heading", "label", "options"],
  },
  multichoice: {
    type: "multichoice",
    text: "Multiple Choices",
    config: ["heading", "label", "options"],
  },
  dropdown: {
    type: "dropdown",
    text: "Dropdown",
    config: ["heading", "label", "options"],
  },
  reaction: {
    type: "reaction",
    text: "Reaction",
    config: ["heading", "label", "options"],
  },
}

const elementsConfig = {
  shorttext: {
    heading: "",
    placeholder: "",
    label: "",
    maxLength: null,
  },
  longtext: {
    heading: "",
    placeholder: "",
    label: "",
    maxLength: null,
  },
  singlechoice: {
    heading: "",
    label: "",
    options: ["Option 1"],
  },
  multichoice: {
    heading: "",
    label: "",
    options: ["Option 1"],
  },
  dropdown: {
    heading: "",
    label: "",
    options: ["Option 1"],
  },
  reaction: {
    heading: "",
    label: "",
    options: [
      "Strongly disagree",
      "Disagree",
      "Unsure",
      "Agree",
      "Strongly agree",
    ],
  },
}

const Editor = ({ desc, setDesc, config, setConfig }) => {
  function handleChange(event, type, parentId) {
    let parentConfigIndex = config.findIndex((c) => c.id === parentId)
    let parentConfig = config[parentConfigIndex]

    if (type === "maxLength") parentConfig[type] = Number(event.target.value)

    parentConfig[type] = event.target.value

    let configClone = [...config]
    configClone[parentConfigIndex] = parentConfig

    setConfig(configClone)
  }

  function handleDropChange(type, parentId) {
    const configType = Object.keys(elements)
      .map((e) => elements[e])
      .find((e) => e.text === type).type

    let parentConfigIndex = config.findIndex((c) => c.id === parentId)
    let parentConfig = config[parentConfigIndex]
    let parentConfigType = parentConfig.type
    let parentConfigClone = { ...parentConfig }

    elements[parentConfigType].config.forEach((c) => {
      delete parentConfigClone[c]
    })

    parentConfigClone = {
      ...parentConfigClone,
      ...elementsConfig[configType],
    }

    parentConfigClone.type = configType

    elements[configType].config.forEach((c) => {
      if (!parentConfig[c]) return

      parentConfigClone[c] = parentConfig[c]
    })

    if (parentConfigClone.type === "reaction") {
      parentConfigClone.options = elementsConfig.reaction.options
    } else {
      parentConfigClone.options = elementsConfig[parentConfigClone.type].options
    }

    let configClone = [...config]
    configClone[parentConfigIndex] = parentConfigClone

    setConfig(configClone)
  }

  function handleOptionsAdd(e, parentId) {
    e.preventDefault()

    const input = e.target.querySelector("input").value.trim()

    if (input.length === 0) return

    let configClone = [...config]

    let parentConfigIndex = config.findIndex((c) => c.id === parentId)
    let parentConfig = configClone[parentConfigIndex]

    if (parentConfig.options) {
      parentConfig.options.push(input)
    }

    configClone[parentConfigIndex] = parentConfig

    setConfig(configClone)

    e.target.querySelector("input").value = ""
  }

  function handleOptionsRemove(index, parentId) {
    let configClone = [...config]

    let parentConfigIndex = config.findIndex((c) => c.id === parentId)
    let parentConfig = configClone[parentConfigIndex]

    if (index < 0 || index > parentConfig.options.length) return

    if (index === 0) {
      parentConfig.options = parentConfig.options.slice(1)
    } else {
      parentConfig.options = [
        ...parentConfig.options.slice(0, index),
        ...parentConfig.options.slice(index + 1),
      ]
    }

    configClone[parentConfigIndex] = parentConfig

    setConfig(configClone)
  }

  /*   function handleOptionsEdit(e, index, parentId) {
    let configClone = [...config]

    let text = e.target.textContent.trim()
    console.log(text)

    let parentConfigIndex = config.findIndex((c) => c.id === parentId)
    let parentConfig = configClone[parentConfigIndex]

    if (index < 0 || index > parentConfig.options.length) return

    if (text.length === 0) handleOptionsRemove(index, parentId)

    parentConfig.options.splice(index, 1, text)

    configClone[parentConfigIndex] = parentConfig

    setConfig(configClone)
  } */

  function handleDelete(id) {
    const filteredConfig = config.filter((c) => c.id !== id)

    setConfig(filteredConfig)
  }

  function handleDuplicate(id) {
    let configClone = [...config]

    const dConfig = configClone.find((c) => c.id === id)

    if (!dConfig) return

    setConfig((c) => [...c, { ...dConfig, id: getRandomId() }])
  }

  return (
    <div className="feedback-editor">
      <div className="info">
        <div>
          <label htmlFor="feedbacktitle">Feedback title</label>
          <input
            id="feedbacktitle"
            onChange={(e) => {
              setDesc((c) => ({ ...c, title: e.target.value }))
            }}
            value={desc.title}
          />
        </div>

        <div>
          <label htmlFor="feedbackdescription">Feedback description</label>
          <textarea
            id="feedbackdescription"
            placeholder="Feedback description"
            onChange={(e) => {
              setDesc((c) => ({ ...c, description: e.target.value }))
            }}
            value={desc.description}
            className="w-full h-40 p-4 bg-gray-50 rounded-lg py-2 px-4 text-base ring-1 ring-gray-200 tracking-tight"
          />
        </div>
      </div>

      <div className="fixed right-6 bottom-6 z-30">
        <button
          onClick={() => {
            setConfig((c) => [
              ...c,
              {
                id: getRandomId(),
                type: "singlechoice",
                label: "",
                options: ["Option 1"],
              },
            ])

            document.querySelector("#editor").lastElementChild.scrollIntoView()
          }}
          className="bg-amber-700 rounded-lg px-4 py-2 shadow-lg flex gap-2 text-white font-semibold border-b-4 border-amber-800 active:border-none active:translate-y-1"
        >
          <DocumentAddIcon className="h-5" /> Add New
        </button>
      </div>

      <div className="editor" id="editor">
        {config.map((c, index) => (
          <div className="element-wrapper relative" key={c.id}>
            {/* <small>{elements[c.type].text}</small> */}
            <div className="bg-sky-900 h-8 w-8 rounded-full text-white font-bold flex items-center justify-center absolute -top-3 -left-3 shadow-2xl">
              {index + 1}
            </div>

            <div className="w-full mb-6">
              <div className="element-field bg-teal-500 text-white p-2 rounded-lg shadow-md">
                <small>Change field type</small>
                <DropListAutocomplete
                  data={Object.keys(elements).map((e) => elements[e].text)}
                  defaultSelected={elements[c.type].text}
                  onChange={(type) => handleDropChange(type, c.id)}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              {elements[c.type].config.includes("label") && (
                <div className="element-field">
                  <small>Label</small>
                  <input
                    id="label"
                    placeholder="label"
                    className="w-full"
                    type="text"
                    value={c.label}
                    onChange={(e) => handleChange(e, "label", c.id)}
                  />
                </div>
              )}

              {elements[c.type].config.includes("heading") && (
                <div className="element-field">
                  <small>Heading</small>
                  <input
                    id="heading"
                    placeholder="heading"
                    className="w-full"
                    type="text"
                    value={c.heading}
                    onChange={(e) => handleChange(e, "heading", c.id)}
                  />
                </div>
              )}

              {elements[c.type].config.includes("placeholder") && (
                <div className="element-field">
                  <small>Placeholder</small>
                  <input
                    id="placeholder"
                    placeholder="placeholder"
                    className="w-full"
                    type="text"
                    value={c.placeholder}
                    onChange={(e) => handleChange(e, "placeholder", c.id)}
                  />
                </div>
              )}

              {elements[c.type].config.includes("maxLength") && (
                <div className="element-field">
                  <small>Max Length</small>
                  <input
                    id="maxLength"
                    placeholder="max length"
                    className="w-full"
                    type="number"
                    value={c.maxLength}
                    onChange={(e) => handleChange(e, "maxLength", c.id)}
                  />
                </div>
              )}

              {elements[c.type].config.includes("options") && (
                <div className="element-field w-full">
                  <small>Options</small>

                  <div className="element-options">
                    {c.options.map((o, index) => (
                      <span key={o} className="element-option">
                        <button
                          onClick={() => handleOptionsRemove(index, c.id)}
                        >
                          <XIcon className="h-4 text-red-100" />
                        </button>

                        <div
                        /* onInput={(e) => handleOptionsEdit(e, index, c.id)}
                          contentEditable */
                        >
                          {o}
                        </div>
                      </span>
                    ))}
                  </div>

                  <form
                    className="flex flex-wrap gap-2"
                    onSubmit={(e) => handleOptionsAdd(e, c.id)}
                  >
                    <input
                      className="text-sm w-full"
                      placeholder="Add option"
                      type="text"
                      id="options"
                    />
                    <button
                      type="submit"
                      className="px-4 py-2 bg-sky-900 text-white rounded-lg shadow-lg text-sm font-semibold"
                    >
                      Add
                    </button>
                  </form>
                </div>
              )}
            </div>

            <div className="flex flex-wrap self-end items-center gap-4 mt-2">
              <button
                onClick={() => handleDuplicate(c.id)}
                className="flex items-center gap-2 bg-sky-100 text-sky-700 py-2 px-4 rounded-lg"
              >
                <DuplicateIcon className="h-6" />
                <small className="text-sky-800">Duplicate</small>
              </button>
              <button
                onClick={() => handleDelete(c.id)}
                className="flex items-center gap-2 bg-red-100 text-red-700 py-2 px-4 rounded-lg"
              >
                <TrashIcon className="h-6" />
                <small className="text-red-800">Delete</small>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Editor
