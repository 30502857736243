import { BrowserRouter, Routes, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"

import AuthLayout from "./layouts/AuthLayout"
import GeneralLayout from "./layouts/GeneralLayout"
import UsersLayout from "./layouts/UsersLayout"
import AdminLayout from "./layouts/AdminLayout"

import Home from "./pages/general/Home"
import Login from "./pages/general/Login"
import Signup from "./pages/general/Signup"

import UsersDashboard from "./pages/users/Dashboard"
import Feedback from "./pages/users/Feedback"
import FeedbackDetails from "./pages/users/FeedbackDetails"
import Settings from "./pages/users/Settings"

import AdminDashboard from "./pages/admin/Dashboard"
import NotFound from "./pages/NotFound"
import AdditionalInfo from "./pages/general/AdditionalInfo"
import SubmitFeedback from "./pages/users/SubmitFeedback"
import RequestData from "./pages/general/RequestData"
import FeedbackEditor from "./pages/admin/FeedbackEditor"
import AdminFeedbacks from "./pages/admin/AdminFeedbacks"
import FeedbackPage from "./pages/admin/FeedbackPage"
import Users from "./pages/admin/Users"
import Schools from "./pages/admin/Schools"
import SchoolReviews from "./pages/admin/SchoolReviews"
import UserProfile from "./pages/admin/UserProfile"
import SubmitRating from "./pages/general/SubmitRating"
import VerifyEmail from "./pages/general/VerifyEmail"
import ContactUs from "./pages/general/Contact"
import TermsAndConditions from "./pages/general/TermsAndConditions"
import About from "./pages/general/About"
import Faqs from "./pages/general/Faqs"
import Reports from "./pages/general/Reports"
import PreReviewSubmit from "./pages/general/SubmitRating/pre-submit"

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route element={<GeneralLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/request-data" element={<RequestData />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/pre-review" element={<PreReviewSubmit />} />
          <Route path="/submit-review" element={<SubmitRating />} />
          <Route path="/additional-info" element={<AdditionalInfo />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/faqs" element={<Faqs />} />
        </Route>

        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
        </Route>

        <Route element={<UsersLayout />}>
          <Route path="/users/dashboard" element={<UsersDashboard />} />
          <Route path="/users/feedbacks" element={<Feedback />} />
          <Route path="/users/feedbacks/:id" element={<FeedbackDetails />} />
          <Route path="/users/submit-feedback" element={<SubmitFeedback />} />
          <Route path="/users/settings" element={<Settings />} />
          <Route path="/users/*" element={<NotFound />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />

          <Route path="/admin/feedbacks" element={<AdminFeedbacks />} />
          <Route path="/admin/feedbacks/edit" element={<FeedbackEditor />} />
          <Route path="/admin/feedbacks/:id" element={<FeedbackPage />} />

          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/users/:id" element={<UserProfile />} />
          <Route path="/admin/schools/:id" element={<Schools />} />
          <Route path="/admin/schools/:id/reviews" element={<SchoolReviews />} />
          <Route path="/admin/*" element={<NotFound />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
