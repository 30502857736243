import { Link } from "react-router-dom"

import girlImage from "../../../../assets/image/chatting-girl.jpg"
import ratingMan from "../../../../assets/image/rating-man2.jpg"
import bagstudentImg from "../../../../assets/image/student-with-bag.jpg";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import "@splidejs/react-splide/css"

const Banner = () => {

  return (
      <Splide options={{rewind: true, autoplay: true, arrows: false, type: "loop", interval: 2000, pauseOnHover: true }} aria-label="React Splide Example">
        <SplideSlide>
          <div
            style={{
              background: `url(${girlImage}), rgba(0, 0, 0, .8) no-repeat top right`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top right",
            }}
            className="h-full flex flex-col gap-y-20 p-10 pt-20 md:p-20 h-[70vh] md:h-[90vh]  justify-center"
          >
            {/* Banner Heading */}
            <div className="flex flex-col gap-y-10">
              <div className="h1 text-5xl font-extrabold flex flex-col gap-y-2">
                <h1 className="h1 text-white">
                  {/* <span className="text-white bg-darkcyan px-1 mb-0 inline-blockss text-sm font-red rounded-lg text-right">Beta</span><br /> */}
                  <span className="text-cyan">Leave your thoughts on</span>
                  <br /> your course of study.
                </h1>
              </div>

              <div className="flex flex-wrap gap-2">
                <Link
                  to="/pre-review"
                  className="inline-flex items-center justify-center px-8 py-3 text-base font-semibold bg-sky-600 text-white shadow-sm rounded-lg hover:bg-darkcyan hover:text-white duration-300"
                >
                  Submit your feedback
                </Link>
              </div>
            </div>

          </div>
        </SplideSlide>

        <SplideSlide>
          <div
            style={{
              background: `url(${bagstudentImg}), rgba(0, 0, 0, .8) no-repeat left`,
              // backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top right",
            }}
            className="h-full flex flex-col gap-y-20 p-10 pt-20 md:p-20 h-[70vh] md:h-[90vh] justify-center"
          >
            {/* Banner Heading */}
            <div className="flex flex-col gap-y-10">
              <div className="h1 text-5xl font-extrabold flex flex-col gap-y-2">
                <h1 className="h1 text-white">
                  <span className="text-cyan">Leave your thoughts on</span>
                  <br /> your school administration.
                </h1>
              </div>

              <div className="flex flex-wrap gap-2">
                <Link
                  to="/pre-review"
                  className="inline-flex items-center justify-center px-8 py-3 text-base font-semibold bg-sky-600 text-white shadow-sm rounded-lg hover:bg-darkcyan hover:text-white duration-300"
                >
                  Submit your feedback
                </Link>
              </div>
            </div>

          </div>
        </SplideSlide>

        <SplideSlide>
          <div
            style={{
              background: `url(${ratingMan}), rgba(0, 0, 0, .8) no-repeat top right`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "top right",
            }}
            className="h-full flex flex-col gap-y-20 p-10 pt-20 md:p-20  h-[70vh] md:h-[90vh] justify-center"
          >
            {/* Banner Heading */}
            <div className="flex flex-col gap-y-10">
              <div className="h1 text-5xl font-extrabold flex flex-col gap-y-2">
                <h1 className="h1 text-white">
                  <span className="text-cyan">Provide feedback on </span>
                  <br /> your school programmes.
                </h1>
              </div>

              <div className="flex flex-wrap gap-2">
                <Link
                  to="/pre-review"
                  className="inline-flex items-center justify-center px-8 py-3 text-base font-semibold bg-sky-600 text-white shadow-sm rounded-lg hover:bg-darkcyan hover:text-white duration-300"
                >
                  Submit your feedback
                </Link>
              </div>
            </div>

          </div>
        </SplideSlide>
        
      </Splide>

  )
}

export default Banner
