import { Link, useNavigate } from "react-router-dom"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import { Dialog, Transition } from '@headlessui/react'
import { useState, Fragment, useEffect } from "react"
import Helmet from 'react-helmet'
import Logo from "../../../assets/Logo"
import "../auth.scss"
import Loader from "../../../components/Loader"
import { User } from "../../../api"
import { toast } from "react-toastify"
import Cookies from "js-cookie";
import Whitelogo from "../../../assets/image/Whitelogo.svg";

const Signup = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({fname: "", lname: "", email: "", password: "", emailType: "personal"});
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  const inputChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  const confirmEmailType = () => {
    setLoading(true);
    User.signup(form).then((res) => {
      setLoading(false);
      toast.success("Account created successfully!");
      Cookies.set("_v__usr_", res.userID);
      navigate("/verify-email");
    }).catch(err => {
      setLoading(false);
      const error = err.response?.data || {message: "An error occured, please check your network and retry."};
      toast.error(error.message);
    })
  }

  return (
    <>


      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Is "{form.email}" your personal or student email?
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className="auth-field">
                      <select name="emailType" id="" className="form-control" onChange={inputChange}>
                        <option value="personal">Personal Email</option>
                        <option value="student">Student Email</option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={confirmEmailType}
                    >
                     {loading ? "Creating account..." : "Submit Form"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Helmet>
        <title>Sign up | Acadametrics</title>
      </Helmet>
      <div className="auth">
        <div className="auth-banner">
          <div className="auth-header">
            <Link to="/">
              {/* <Logo className="h-10 text-white logo-dark" /> */}
              <img src={Whitelogo} alt="" className="h-13"/>
            </Link>

            <span>Rate and assess your school programmes</span>
          </div>

          <div className="auth-footer">
            <Link to="/">PRIVACY POLICY </Link>
            <span>.</span>
            <Link to="/"> TERMS OF SERVICE</Link>
          </div>
        </div>

        <form className="auth-form" onSubmit={handleSubmit}>
          <Link className="self-center mb-8 block lg:hidden" to="/">
            <Logo className="h-10 logo" />
          </Link>

          <div className="auth-form__header">
            <h4 className="h4">Sign up</h4>
            <span className="text-gray-400">Enter your details below</span>
          </div>

          <div className="auth-field">
            <span>
              <label htmlFor="email">First Name</label>
            </span>
            <input
              id="firstname"
              name="fname"
              type="text"
              placeholder="Ex: Abdullah"
              required
              onChange={inputChange}
              value={form.fname}
            />
          </div>

          <div className="auth-field">
            <span>
              <label htmlFor="email">Last Name</label>
            </span>
            <input
              id="lastname"
              name="lname"
              type="text"
              placeholder="Ex: John"
              required
              onChange={inputChange}
              value={form.lname}
            />
          </div>

          <div className="auth-field">
            <span>
              <label htmlFor="email">Email</label>
            </span>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="example@example.edu.ng"
              required
              value={form.email}
              onChange={inputChange}
            />
          </div>

          <div className="auth-field forgot">
            <span className="forgot-label">
              <label htmlFor="password">Password</label>
              {/* <span className="text-blue-500">Forgot password?</span> */}
            </span>

            <div>
              <input
                id="password"
                name="password"
                type={show ? "text" : "password"}
                placeholder="Choose a password"
                required
                onChange={inputChange}
                value={form.password}
              />

              <button className="btn" type="button" onClick={() => setShow(!show)}>
                {show ? (
                  <EyeOffIcon className="h-4" />
                ) : (
                  <EyeIcon className="h-4" />
                )}
              </button>
            </div>
          </div>

          <div className="auth-foote text-center">
            By submitting this form, you agree to the website <u><Link to="/terms-and-conditions"> TERMS AND CONDITIONS.</Link></u>
          </div>
          <button className="submit-button" type="submit">
            {loading ? <Loader /> : 'Submit' }
          </button>

          <span className="auth-direct">
            Have an account?{" "}
            <Link to="/login">
              Login
            </Link>
          </span>
        </form>
      </div>
    </>
  )
}

export default Signup
