import React, { useEffect } from "react"
import Helmet from "react-helmet"
import Banner from "./components/Banner"
import SliderBanner from "./components/SliderBanner"
import HomeChart from "./components/HomeChart"
import ImpactReport from "./components/ImpactReport"
import Metrics from "./components/Metrics"
import Subscribe from "./components/Subscribe"

import "./home.scss"
import AboutContainer from "./components/AboutContainer"
import SubChart from "./components/SubChart"
import InstitutionRating from "./components/InstitutionRating"

const Home = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
      <Helmet>
        <title>Home | Akadametrix</title>
      </Helmet>

      <SliderBanner />
      {/* <Banner /> */}
      <HomeChart />
      {/* <ImpactReport /> */}
      <AboutContainer/>
      <SubChart />
      <InstitutionRating />
      <Metrics />
      <Subscribe />
    </>
  )
}

export default Home
