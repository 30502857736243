import React from "react";
import "./about.scss";
import professorImage from "../../../assets/image/professor.jpg";
import chattingGirl from "../../../assets/image/chatting-girl1.jpg";
import ratingMan from "../../../assets/image/rating-man.jpg";
import lockSvg from "../../../assets/image/lock.svg"
import { Link } from "react-router-dom";

const About = () => {

  return (
    <div>

      <div className="about">
        <div className="head">
          <h2 className="h2">About AkadaMetrix</h2>
        </div>
        <p>Welcome to the AkadaMetrix Student Experience Survey in Nigeria! We are a dedicated team of educators, researchers, and advocates committed to improving the academic and social experiences of students across the country. Our goal is to create a platform that amplifies the voice of students, enabling them to contribute to positive changes in the Nigerian education system.</p>
      </div>

      <div className="mission bg-indigodark">
        <div className="head">
          <h2 className="h2 text-white">Our Mission</h2>
        </div>

        <div className="flex mission-statement">
          <img src={chattingGirl} />
          <div></div>
          <p className="text-white">At the core of our mission is the belief that every student deserves a high-quality education and a vibrant social environment that nurtures personal growth. We understand that the experiences students have during their time in higher education can shape their future success and overall well-being. By conducting this national survey, we aim to collect valuable feedback directly from students like you to understand the current landscape, identify areas for improvement and make the report available for relevant stakeholders.</p>
        </div>
      </div>

      <div className="objectives">
        <div className="head">
          <h2 className="h2">Our Objectives</h2>
        </div>

        <div className="flex mission-statement">
          <div>
            <p>● Gather comprehensive data on the academic and social experiences of students in Nigerian universities, polytechnics and colleges.</p>
            <p>● Identify strengths and weaknesses within the education system, focusing on teaching quality, course content, assessment methods, campus life, and support services.</p>
            <p>● Influence policy decisions and advocate for positive changes that address the needs and aspirations of students.</p>
            <p>● Foster a culture of transparency, accountability, and continuous improvement within educational institutions.</p>
            <p>● Provide students with a platform to voice their opinions, concerns, and suggestions to drive meaningful change.</p>
          </div>
          <img src={ratingMan} />
        </div>
      </div>

      <div className="bg-cyan px-8 py-6 rounded-lg about-confidentiality">
        <h2 className="h4 text-white">Your privacy is of utmost importance to us.</h2>
        <p>We are committed to ensuring the confidentiality and anonymity of all survey participants. Your privacy is of utmost importance to us, and your personal information will be protected and kept strictly confidential.</p>
        <br />
        <p>Join us in our mission to enhance the national student experience in Nigeria. Together, we can create a brighter future for all students by advocating for a more inclusive, supportive, and enriching educational environment.</p>
        <div className="flex gap-4 items-center flex-wrap">
          <img className="h-20" alt="Security lock" src={lockSvg} />

          <Link to={"/pre-review"} className="px-6 py-3 inline-block bg-white text-black font-bold rounded-lg h-12">Submit feedback</Link>
        </div>
      </div>
      
    </div>
  )
}

export default About
