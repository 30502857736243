import React from "react"
import chatimg from "../../../../assets/image/chat-icon.png";
import { Combobox, Transition } from "@headlessui/react"
import { Link, useNavigate } from "react-router-dom"
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid"
import { useState, Fragment, useEffect } from "react";
import { Feedback } from "../../../../api";

const AboutContainer = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(null)
    const [query, setQuery] = useState("");
    const [feedbackTypes, setFeedbackTypes] = useState([]);

    useEffect(() => {
  
      Feedback.getFeedbackTypes().then(res => {
        setFeedbackTypes(res.feedbackTypes.sort((a,b) => a.title-b.title));
      setSelected(res.feedbackTypes[0].title.split(" ")[0])
      })
  
      return
    }, [navigate])
    
  const filteredData =
  query === ""
    ? feedbackTypes
    : feedbackTypes.filter((d) =>
        d.title.split(" ")[0]
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
      )

    return (
        <div className="flex justify-around gap-10 bg-blue-300 px-10 py-20 items-center flex-wrap">
            <div className="md:w-[50%]">
                <h4 className="h4">IMPACT REPORT</h4>

                <p className="my-3">
                We understand the importance of your academic journey and its impact on your future. Through this survey, we aim to gather information about your academic experiences, including teaching quality, course content, assessment methods, and support services provided by your institution. Your feedback will help us identify and present areas of improvement with the hope that relevant stakeholders recognise and use the same to ensure that the education system in Nigeria meets the needs and expectations of students like you.
                </p>
                
                <div className="relative text-xl font-semibold text-center w-max bg-indigodark px-3 py-5 home-feedbacks-dropdown mt-5">
                    <div className="flex items-center justify-center flex-wrap gap-5">
                        <div className="text-white">REVIEW YOUR</div>
                        <div className="">
                        <Combobox
                            value={selected}
                            onChange={(d) => {
                            setSelected(d);
                            }}
                        >
                            <div className={`relative w-max`}>
                            <div className="relative w-full cursor-default overflow-hidden bg-white text-left sm:text-sm">
                                <Combobox.Input
                                className="bg-gray-50 w-full py-[0.6rem] pl-3 pr-4 text-xl font-semibold leading-5 text-gray-900"
                                displayValue={(d) => d}
                                onChange={(event) => setQuery(event.target.value)}
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                                </Combobox.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={() => setQuery("")}
                            >
                                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-30">
                                {filteredData.length === 0 && query !== "" ? (
                                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                    </div>
                                ) : (
                                    filteredData.map((d, index) => (
                                    <Combobox.Option
                                        key={d.id}
                                        className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                            active ? "bg-sky-600 text-white" : "text-gray-900"
                                        }`
                                        }
                                        value={d.title.split(" ")[0]}
                                        
                                    >
                                        {({ selected, active }) => (
                                        <>
                                            <span
                                            className={`block truncate ${
                                                selected ? "font-medium" : "font-normal"
                                            }`}
                                            >
                                            {d.title.split(" ")[0]}
                                            </span>
                                            {selected ? (
                                            <span
                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                active ? "text-white" : "text-sky-600"
                                                }`}
                                            >
                                                <CheckIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                                />
                                            </span>
                                            ) : null}
                                        </>
                                        )}
                                    </Combobox.Option>
                                    ))
                                )}
                                </Combobox.Options>
                            </Transition>
                            </div>
                        </Combobox>
                        </div>
                    
                        <Link
                        className="mobile-full inline-flex justify-center items-center px-3 py-2 text-base font-semibold bg-white text-indigodark shadow-sm rounded-lg hover:bg-darkcyan hover:text-white duration-300"
                        to={`/submit-review?type=${selected}`}>Start&gt;&gt;
                        </Link>
                    </div>
                </div>
            </div>

            <div  className="md:w-[40%]">
                <img src={chatimg} alt={"Akadametrix"} width={"90%"} />
            </div>
        </div>
    )
}

export default AboutContainer
