import { UserCircleIcon, UserIcon } from "@heroicons/react/solid"
import FeedbackMenu from "./FeedbackMenu"

const imagesUrl = {
  institution: "institution.svg",
  course: "course.png",
  department: "department.svg",
  faculty: "faculty.svg",
  module: "department.svg",
}

const FeedbackCard = ({ type, title, time, status, id }) => {


  return (
    <div className="card cursor-pointer hover:opacity-80 duration-200">
      <div className="image">
        <img
          className="h-10"
          src={`/images/${imagesUrl[type]}`}
          alt="Feedback"
        />
        {/* <UserIcon /> */}
      </div>
      <span className={"status " + status}>{status.slice(0,1).toUpperCase() + status.slice(1)}</span>

      <div>
        <h4 className="h4 text-gray-700 font-semibold">
        { type.charAt(0).toUpperCase() + type.slice(1) } feedback
        </h4>

        <FeedbackMenu id={id} />
      </div>

      <div className="metadata">
        <span className="type">
          Feedback on your {title.length > 28 ? `${title.substring(0, 25).toLowerCase()}...` : title.toLowerCase()}.
        </span>

        <span className="date">{time}</span>
      </div>

    </div>
  )
}

export default FeedbackCard
