import FilterableTable from "react-filterable-table"
// import { Link } from "react-router-dom"

// import Table from "./Table"
import { DocumentDownloadIcon } from "@heroicons/react/outline"
import tableData from "../tableData.json"
import { useState } from "react"
import SubmissionModal from "./SubmissionModal"

/* const columns = [
  { label: "User Type", accessor: "user_type", sortable: true },
  { label: "User Id", accessor: "user_id", sortable: true },
  {
    label: "Institution",
    accessor: "institution",
    sortable: true,
    sortbyOrder: "desc",
  },
  { label: "Submission date", accessor: "submission_date", sortable: true },
] */

const App = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentSubmission, setCurrentSubmission] = useState(null)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const renderName = (props) => {
    // return <Link to="/admin/submissions/uiuiqiu291uiw">{props.value}</Link>
    return (
      <button
        onClick={() => {
          console.log(props)
          setCurrentSubmission(props.record)
          openModal()
        }}
      >
        {props.value}
      </button>
    )
  }

  const fields = [
    {
      name: "user_type",
      displayName: "User Type",
      inputFilterable: true,
      sortable: true,
    },
    {
      name: "user_id",
      displayName: "User Id",
      inputFilterable: true,
      sortable: true,
      render: renderName,
    },
    {
      name: "institution",
      displayName: "Institution",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "submission_date",
      displayName: "Submission date",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
  ]

  return (
    <div className="submissions">
      <button className="px-4 py-2 bg-amber-700 text-white font-semibold flex items-center gap-2 shadow-md rounded-lg">
        Export Data <DocumentDownloadIcon className="h-5" />
      </button>

      <div className="table_container">
        {/* <Table data={tableData1} columns={columns} /> */}
        <FilterableTable
          namespace="Submissions"
          initialSort="name"
          data={tableData}
          fields={fields}
          noRecordsMessage="There are no submissions to display"
          noFilteredRecordsMessage="No submission match your filters!"
        />
      </div>

      <SubmissionModal
        isOpen={isOpen}
        closeModal={closeModal}
        currentSubmission={currentSubmission}
      />
    </div>
  )
}

export default App
