import React from "react";
import "./faq.scss";
import {Link} from "react-router-dom"
import { CogIcon } from "@heroicons/react/solid";

const Faqs = () => {

  return (
    <div>
      <div className="about">
        <div className="head">
          <h2 className="h2">FAQs</h2>
        </div>
        <p>Frequently Asked Questions.</p>
      </div>
      
      <div className="w-full flex justify-center items-center">
        <div className="flex flex-col items-center gap-1 mt-0">

          <div className="space-y-1 text-center">
            <div>
              <h2 className="h2 font-black text-black text-gray-600">Under Construction.</h2>
            </div>

            <CogIcon color="rgba(2, 132, 199, 1)" width={"200px"} style={{margin: "auto"}} />
            <div className="space-x-2">
              <Link
                to="/"
                className="bg-sky-600 text-white font-semibold text-base py-3 px-4 rounded-lg inline-block  mb-10"
              >
                Go back home
              </Link>
              <Link
                to="/contact"
                className="bg-sky-100 text-sky-700 border border-sky-200 font-semibold text-base py-3 px-4 rounded-lg inline-block mb-10"
              >
                Contact support
              </Link>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Faqs;
