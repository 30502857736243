import "./InputText.scss"

const InputText = ({ config, handleChange, value, disabled = false }) => {
  return (
    <div className="inputtext">
      {config.heading && <h5 className="h5  mb-0  mt-0 font-semibold">{config.heading}</h5>}
      <div>
        <label>{config.label}</label>
        <input className="w-full" type="text" placeholder={config.placeholder} maxLength={config.maxLength} disabled={disabled} onChange={handleChange} value={value} required />
      </div>
    </div>
  )
}

export default InputText
