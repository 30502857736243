import CardMenu from "./CardMenu"

const FeedbackCard = ({ name, createdAt, updatedAt }) => {
  return (
    <tr className="card">
      <td>{name.length > 28 ? `${name.substring(0, 25)}...` : name}</td>

      <td>{createdAt}</td>
      <td>{updatedAt}</td>

      <td className="flex flex-wrap gap-4 text-sm">
        <CardMenu name={name} />
      </td>
    </tr>
  )
}

export default FeedbackCard
