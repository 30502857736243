import "./SingleChoice.scss"

const SingleChoice = ({ config, handleChange, value, disabled = false }) => {
  return (
    <div className="singlechoice">
      {config.heading && <h5 className="h5 mb-0  mt-0 font-semibold">{config.heading}</h5>}
      
      <label>{config.label}</label>

      <div className="choice">
        {config.options.map((o) => (
          <div key={o}>
            <input type="radio" name={config.id} value={o} disabled={disabled} checked={value === o} onChange={handleChange} required />
            <span>{o}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SingleChoice
